<!--
 * @Description: 无牌车查询订单 noPlateNumberPayEntrance 有关订单页面
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-17 13:52:18
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单详情" />
    <van-row v-if="showPage===1"
             class="mainbody">
      <van-row>
        <van-row class="mainitem">{{toast}}</van-row>
      </van-row>
    </van-row>
    <van-row class="main"
             v-if="showPage===2">
      <van-row class="top-text">
        离场时，请使用该微信扫描出口立柱显示屏上的无牌车二维码，若没有显示无牌车二维码，请倒车重试或联系客服
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoTitle">
          <img :src="require('@/assets/commonPage/orderIcon.png')"
               alt="">
          <span class="wenzi">订单详情</span>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">订单号</van-col>
          <van-col class="details">{{orderList.orderSequence?orderList.orderSequence:""}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">无牌车</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName?orderList.parkName:""}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime?orderList.entranceTime:""}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration?orderList.parkDuration:"0"}}</van-col>
        </van-row>
        <van-row class="infoLine"
                 v-if="billRuleCode">
          <van-col class="name">计费规则</van-col>
          <van-col style="color:red">
            <span v-if="billRuleCode===1">半小时1元</span>
            <span v-if="billRuleCode===2">1小时2元</span>
          </van-col>
          <van-col style="color:blue;margin-left: 20px"
                   @click="queryBillingRuleDetail">详情</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">收款单位</van-col>
          <van-col class="details">中交四公局(重庆)城市建设发展有限公司</van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.receivableMoneyAmount?orderList.receivableMoneyAmount:""}}</span>
            <span v-if="showFree===2"> - </span>
            <span style="color:red;font-size:20px"
                  v-if="showFree===2">{{orderList.receivableMoneyAmount?orderList.receivableMoneyAmount:""}}</span>元
          </van-col>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import formatDuration from '@/utils/duration'
import parkStopTime from '@/utils/parkStopTime'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      openid: '',
      deviceId: '',
      toast: '卡口前暂无车辆',
      showPage: 0, // 展示页面进场出场 1第一次扫码 2 第二次扫码
      showFree: 1, // 计费规则是否免费 2是免费 1是收费
      orderList: {}, // 订单详情列表
      orderSequence: '', // 订单号
      billingRuleDefId: '', // 计费规则编号
      billRuleCode: 0,
      parkId: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryOpenid()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {

  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取code
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // 获取openid
    queryOpenid () {
      // 第一次进
      if (!sessionStorage.getItem('openid')) {
        const info = {
          code: this.getQueryString('code'),
          type: 'officialAccount'
        }
        this.showPage = 1
        this.$noPlateNumberPay.getOpenIdByCode(info).then(res => {
          sessionStorage.setItem('openid', res.resultEntity.openid)
          this.queryOrderList()
        })
      } else {
        this.queryOrderList()
      }
    },
    // 获取订单详情列表
    queryOrderList () {
      const currentHref = window.location.href
      const deviceId = currentHref.split('deviceId=')[1]
      this.deviceId = currentHref.split('deviceId=')[1]
      const info = {
        openid: sessionStorage.getItem('openid'),
        deviceId: deviceId
      }
      this.$noPlateNumberPay.getOrderByOpenid(info).then(res => {
        const self = this
        if (!res.resultEntity) {
          self.showPage = 1
          const info1 = {
            deviceId: deviceId,
            openid: sessionStorage.getItem('openid')
          }
          self.$noPlateNumberPay.createOrder4NoPlate(info1).then(res => {
            if (res.resultEntity) {
              self.$toast.success('登记成功')
              self.toast = '登记成功'
              self.showPage = 2
              self.orderList = res.resultEntity
              // self.orderList.parkDuration = formatDuration(self.orderList.parkDuration)
              // 如果无离场时间 需要自己算
              if (!self.orderList.leaveTime) {
                self.orderList.parkDuration = parkStopTime(self.orderList.entranceTime, self.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss'))
              } else {
                self.orderList.parkDuration = parkStopTime(self.orderList.entranceTime, self.orderList.leaveTime)
              }
              self.orderSequence = res.resultEntity.orderSequence
              // 应收金额等于=应收-实收
              self.orderList.receivableMoneyAmount =
                Number((self.orderList.receivableMoneyAmount - self.orderList.receivedMoneyAmount) / 100).toFixed(2)
              self.billingRuleDefId = res.resultEntity.billingRuleDefId
              if (self.billingRuleDefId === 'V7') {
                self.billRuleCode = 1
              } else if (self.billingRuleDefId === '1') {
                self.billRuleCode = 2
              }
              self.parkId = res.resultEntity.parkId
              self.queryForFree()
            } else {
              self.$toast.fail('卡口前暂无车辆')
              self.toast = '卡口前暂无车辆'
            }
          })
        } else {
          self.showPage = 2
          self.orderList = res.resultEntity
          // 应收金额等于=应收-实收
          self.orderList.receivableMoneyAmount =
            Number((self.orderList.receivableMoneyAmount - self.orderList.receivedMoneyAmount) / 100).toFixed(2)
          // self.orderList.parkDuration = formatDuration(self.orderList.parkDuration)
          if (!self.orderList.leaveTime) {
            self.orderList.parkDuration = parkStopTime(self.orderList.entranceTime, self.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss'))
          } else {
            self.orderList.parkDuration = parkStopTime(self.orderList.entranceTime, self.orderList.leaveTime)
          }
          self.orderSequence = res.resultEntity.orderSequence
          self.billingRuleDefId = res.resultEntity.billingRuleDefId
          if (self.billingRuleDefId === 'V7') {
            self.billRuleCode = 1
          } else if (self.billingRuleDefId === '1') {
            self.billRuleCode = 2
          }
          self.parkId = res.resultEntity.parkId
          self.queryForFree()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 查询该计费规则是否收费
    queryForFree () {
      const info = {
        billingRuleDefId: this.billingRuleDefId
      }
      this.$payForParking.queryBillingRuleById(info).then(res => {
        // 2是免费 1是收费
        if (res.resultEntity.chargeStatusCode === 2) {
          this.showFree = 2
        } else if (res.resultEntity.chargeStatusCode === 1) {
          this.showFree = 1
        }
      })
    },
    // 查看计费规则详情
    queryBillingRuleDetail () {
      this.$router.push({
        name: 'billRuleDetail',
        query: { billRuleCode: this.billRuleCode }
      })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { parkId: this.parkId }
      // })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { billingRuleDefId: this.billingRuleDefId }
      // })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .mainbody {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .mainitem {
      margin-top: 20px;
    }
  }
  .main {
    height: 100px;
    .top-text {
      padding: 10px;
      font-size: 14px;
      background: #ffb542 30%;
      color: #a47533;
    }
    .infoBox {
      margin: 20px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      // height: 100px;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .wenzi {
          margin-left: 10px;
        }
      }
      .line {
        border: 1px dashed #cfcfcf;
        width: 92.8%;
        margin-left: 12px;
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 80px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: calc(100% - 80px - 12px);
          min-height: 33.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 200px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .payBtn {
      width: 93.6%;
      margin: 0 3.2%;
      border-radius: 5px;
      background: #19a9fc;
      font-size: 17px;
    }
  }
}
</style>
